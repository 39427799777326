<template>
    <div class="partner-center-box" id="partner-center-box">
        <title-nav :title="'加入合伙人'"/>
        <div class="partner">
          <my-table :columns="columns" :data="tableList" />
        </div>
        <div class="partner-row">
            <div v-for="(list) in lists" :key="list.id" :class="list.isSelected ? 'card-active' : 'card'" @click="handleClick(list)">
                <div class="partner_type">{{ list.partnerType }}</div>
                <div class="price_wrap">
                  <div class="new_user_price"><span style="font-size:16px">￥</span>{{ list.newUserPrice }}</div>
                </div>
            </div>
        </div>
        <div class="coupon-row">
            <div class="coupon" @click="selectCoupon">
                <span>{{ couponText }}</span>
                <img src="../../assets/images/enter.png" alt=""  width="20" height="20">
            </div>
        </div>
        <div class="row">
            <div :class="isSelected ? 'activate-now' : 'activate-now-grey'" @click="activateNow">成为合伙人</div>
        </div>
        <van-popup v-model="showPopup" position="bottom" round :style="'height: 50%;max-width:625px;'" @close="handleClose" closeable>
          <div class="pay-details">
            <div class="title" v-if="!isPayCancel">请选择支付方式</div>
            <div class="title" v-else>请在<van-count-down :time="time" @finish="finish"/>分钟内支付</div>
            <div class="wechat-pay" @click="wechatPayClick">
                <div class="row">
                    <img src="../../assets/images/wechat-pay.png" alt="" width="35" height="35">
                    <span>微信</span>
                </div>
                <van-checkbox v-model="wechatChecked" disabled></van-checkbox>
            </div>
            <div class="alipay" @click="alipayClick" v-show="!isWeiXin">
                <div class="row">
                    <img src="../../assets/images/alipay.png" alt="" width="35" height="35">
                    <span>支付宝</span>
                </div>
                <van-checkbox v-model="alipayChecked" disabled></van-checkbox>
            </div>
            <div class="button-row">
                <div class="button-buy" @click="submitOrder" >
                    <van-loading color="white" size="24px" v-show="isLoading"/>
                    <span v-show="!isLoading">{{ alipayChecked ? '支付宝' : '微信' }}付款</span>
                </div>
            </div>
          </div>
        </van-popup>
    </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {Toast} from 'vant'
import moment from 'moment'
import { getopenIdByCode, getBaseInfos, wxSdkShare } from '@/utils/index';
import { wxSdk, updateOrderByOid, queryCouponByCode, submitWechatPay, updatePartnerAsset, createOrder, redeemCouponsByCoid,
     queryOrderById, queryPromotionCode, queryUserByUid } from '@/api/api'
import TitleNav from '../../components/nav/TitleNav.vue'
import myTable from '../../components/table/index.vue'
const seo = require('../../../config/index')

export default {
  name: 'PartnerCenter',
  components: {
    TitleNav,
    myTable
  },
  mounted(){
    this.isWeiXin = this.isWeiXinUA()
    if(this.isWeiXin){
        this.wxShareInit()
    }
    // 二级域名重定向至可付款域名，并携带userInfo
    this.partnerRedirect()
    // 默认选择第一个
    this.handleClick(this.lists[0])
    // 重定向到带推广码路由
    this.routerTimer = setTimeout(()=>{
        if(sessionStorage.getItem('promoCode')){
            this.$router.push({ path:'/partner', query:{ promo_code: sessionStorage.getItem('promoCode') } })
            sessionStorage.removeItem('promoCode')
        }
    },0)
  },
  destroyed(){
    clearTimeout(this.routerTimer)
  },
  data:()=>{
    return {
      columns: [
        { title: '权益/级别', field: 'equity', color: '#f3f3f3' },
        { title: '普通合伙人', field: 'junior', color: '#fdf6ec' },
        { title: '高级合伙人', field: 'senior', color: '#f0f9eb' },
      ],
      tableList: [
        { equity: '送月卡会员', junior: '有', senior: '无' },
        { equity: '送年卡会员', junior: '无', senior: '有' },
        { equity: '合伙人后台', junior: '有', senior: '有' },
        // { equity: '新用户分成', junior: 'T', senior: 'T' },
        { equity: '新会员分成', junior: '低', senior: '高' },
        { equity: '合伙人分成', junior: '低', senior: '高'},
        { equity: '公众号搭建', junior: '无', senior: '有' },
        // { equity: '卡密销售分成', junior: 'F', senior: 'T' },
      ],
      lists:[{
        id:'junior',
        partnerType: '普通合伙人',
        newUserPrice: 988,
        defaultPrice: 988,
        isSelected: false, // 是否被选中
      },{
        id:'senior',
        partnerType: '高级合伙人',
        newUserPrice: 1688,
        defaultPrice: 1688,
        isSelected: false, // 是否被选中
      }],
      orderInfo:{
          gid:'',
          partnerId: '', // 合伙人类别id
          title:'', // 会员类别
          type:'partner', // 款式标注为partner
          expressCharge: 0,  // 配送费
          originalCost: 0,  // 商品单价
          totalPrice: 0, // 商品单价 * 下单商品数量
          finalPayPrice: 0, // 成交价
          discount: 0, // 折扣
          couponDiscount: 0, // 优惠券的折扣
          couponDiscountType: 1, // 1-立减券，2-折扣券
          createAt:'',
          amount:1,
          imgUrl: '/public/images/partner.png',
      },
      showPopup: false, // 支付弹窗
      isPayCancel: false,
      time: 0,
      alipayChecked: false,
      wechatChecked: true,
      oid: '', // 订单号
      isWeiXin: false,
      isLoading: false,
      isSelected: false, // 记录是否有勾选
      couponText: '使用优惠券',
      promoCode: '',
      isLogin: localStorage.getItem('token') ? true : false,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
    }
  },
  methods:{
    handleClose(){
        this.showPopup = false
        queryOrderById({ oid: this.oid }).then((res)=>{
            if(Object.keys(res.data.data).length > 0){
                sessionStorage.setItem('fromPath', 'partner')
                this.$router.push('/order')
            }
        })
    },
    finish(){
        this.showPopup = false
    },
    wechatPayClick(){
        this.alipayChecked = false
        this.wechatChecked = true
    },
    alipayClick(){
        if(!this.isWeiXin){
            this.alipayChecked = true
            this.wechatChecked = false
        }
    },
    isWeiXinUA() {
        let ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true;
        } else {
            return false;
        }
    },
    wxShareInit(){
      let params = {}
      params.url = seo.config.wxConfig.partnerPage.link + this.$route.fullPath
      params.context = seo.config.wxConfig.partnerPage.desc
      params.title = seo.config.wxConfig.partnerPage.title
      params.imgUrl = seo.config.wxConfig.partnerPage.imgUrl
      params.appId = seo.config.wxConfig.appId
      wxSdkShare(params)
    },
    partnerRedirect(){
        // 保存userInfo
        if(this.$route.query.uid && this.$route.query.uid.length > 0){
            this.getUserInfo(Number(this.$route.query.uid))
        }
        // 重定向
        if(this.isWeiXinUA()){
            if(!localStorage.getItem('openid') || localStorage.getItem('openid') === 'undefined'){
                const uidTemp = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0].uid : ''
                let promo_code = this.$route.query.promo_code ? this.$route.query.promo_code : ''
                let url;
                if(promo_code.length > 0){
                    url = seo.config.homeUrl + '/partner?promo_code=' + promo_code + 'uid=' + uidTemp;
                } else {
                    url = seo.config.homeUrl + '/partner?uid=' + uidTemp;
                }
                window.location.href = url;
                setTimeout(()=>{
                    getBaseInfos('/partner')
                    getopenIdByCode()
                },0)
            }
        }
    },
    getUserInfo(uid){
        queryUserByUid({uid: uid}).then((res)=>{
                localStorage.setItem('userInfo', JSON.stringify([res.data.data]))
                // login({ uid: res.data.data[0].uid }).then(result=>{
                //     localStorage.setItem('token', result.data.token)
                // })
                localStorage.setItem('token','13545435213521215')
        })
    },
    getCouponInfo(){
        if(this.$route.query.couponCode){
            queryCouponByCode({ coupon_code: this.$route.query.couponCode, uid: this.userInfo.uid }).then(res=>{
                this.orderInfo.couponDiscount = Number(res.data.data[0].discount_amount)
                this.orderInfo.couponDiscountType = Number(res.data.data[0].type)
                if(this.orderInfo.couponDiscountType === 1){
                    this.couponText = '已优惠'+ Number(res.data.data[0].discount_amount) + '元'
                } else if(this.orderInfo.couponDiscountType === 2){
                    this.couponText = '已使用'+ Number(res.data.data[0].discount_amount) + '折券'
                }
            })
        }
    },
    submitOrder(){
        let params = {}
        this.oid = new Date().getTime() // 生成订单号
        params.oid =  this.oid
        params.price = this.orderInfo.finalPayPrice
        params.title = this.orderInfo.title
        params.openid = localStorage.getItem('openid') ? localStorage.getItem('openid') : ''
        this.createPartnerOrder() // 创建会员订单
        if(this.orderInfo.finalPayPrice > 0){
            this.isLoading = true
            if(this.isWeiXin){
                submitWechatPay(params).then(res=>{
                    this.wxSdk(res.data)
                })
            } else {
                Toast('请前往公众号支付');
                this.isLoading = false
            }
        }
    },
    handleClick(list){
        // 已勾选
        this.isSelected = true
        // 1-立减券 2-折扣券
        if(this.orderInfo.couponDiscountType === 1){
            this.orderInfo.finalPayPrice = list.newUserPrice - this.orderInfo.couponDiscount // 成交价
        } else {
            this.orderInfo.finalPayPrice = list.newUserPrice * this.orderInfo.couponDiscount * 0.1 // 成交价
        }
        this.orderInfo.originalCost = list.defaultPrice // 商品单价
        this.orderInfo.totalPrice = list.newUserPrice // 商品单价 * 下单商品数量
        this.orderInfo.discount = list.newUserPrice - this.orderInfo.finalPayPrice // 总价减成交价
        this.orderInfo.title = list.partnerType
        this.orderInfo.partnerId = list.id
        // 选中后改变isSelected值
        this.lists.forEach(item => {
            if (item.id === list.id) {
                this.$set(item, 'isSelected', true);
            } else {
                this.$set(item, 'isSelected', false);
            }
        });
    },
    // 使用优惠券
    selectCoupon(){
        sessionStorage.setItem('fromPath','partner')
        if(this.isLogin){
            if(this.$route.query.promo_code){
                // range为1-开通会员 2-加入合伙人
                this.$router.push({ path:'/coupon', query:{ promo_code: this.$route.query.promo_code, range: 2 } })
            } else {
                this.$router.push({ path:'/coupon', query:{ range: 2 } })
            }
        } else {
            if(this.$route.query.promo_code){
                sessionStorage.setItem('promoCode', this.$route.query.promo_code)
            }
            this.$router.push({ name:'Login', params:{ text: '用卡券' }})
        }
    },
    // 立即开通按钮submit
    activateNow(){
        if(this.isLogin){
            // 获取promo_code
            this.getPromoCode()
            if(this.isSelected){
                this.showPopup = true
            } else {
                Toast('请选择');
            }
        } else {
            sessionStorage.setItem('fromPath', 'partner')
            if(this.$route.query.promo_code){
                sessionStorage.setItem('promoCode', this.$route.query.promo_code)
            }
            this.$router.push({ name:'Login', params:{ text: '开合伙' }})
        }
    },
    // 创建会员订单
    createPartnerOrder(){
        let paramsVal = {}
        paramsVal.oid = this.oid
        paramsVal.nickname = this.userInfo.nickname
        paramsVal.phone_num = this.userInfo.phoneNum
        paramsVal.address = ''
        paramsVal.uid = this.userInfo.uid
        paramsVal.shop = seo.config.shopName
        paramsVal.title = this.orderInfo.title
        paramsVal.imgUrl = this.orderInfo.imgUrl
        paramsVal.type = this.orderInfo.type
        paramsVal.price = this.orderInfo.originalCost
        paramsVal.amount = this.orderInfo.amount
        paramsVal.total_price = this.orderInfo.totalPrice
        paramsVal.discount = this.orderInfo.discount
        paramsVal.parcel_fee = 0
        paramsVal.finally_price = this.orderInfo.finalPayPrice
        paramsVal.payment = '1'  // 付款方式，1-wechat、2-alipay 默认微信支付
        paramsVal.pay_time = ''  // 订单只是提交，暂未支付
        paramsVal.order_status = 'orderPaying' // 待付款状态
        createOrder(paramsVal) // 创建订单
    },
    // 获取推广码
    getPromoCode(){
        queryPromotionCode({ uid: this.userInfo.uid }).then(res=>{
            console.log(res)
            this.promoCode = res.data.data
        })
    },
    wxSdk(payRes){
        let url = seo.config.homeUrl + this.$route.fullPath
        let my = this
        wxSdk({ url:url }).then(res=>{
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: seo.config.wxConfig.appId, // 必填，公众号的唯一标识
                timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                signature: res.data.data.signature,// 必填，签名
                jsApiList: ["checkJsApi", "chooseWXPay", "updateAppMessageShareData", "updateTimelineShareData"] // 必填，需要使用的JS接口列表
            });
            wx.ready(function() {
                /* 微信支付 */
                wx.chooseWXPay({
                    appId: payRes.appId,
                    timestamp: payRes.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
                    nonceStr: payRes.nonceStr, // 支付签名随机串，不长于 32 位
                    package: payRes.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                    signType: payRes.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
                    paySign: payRes.paySign, // 支付签名
                    // 支付成功
                    success: function (res) {
                        console.log('支付成功', res)
                        if(res.errMsg === 'chooseWXPay:ok'){
                            let payment = '1' // 1-微信支付，2-支付宝支付
                            let payTime = moment(payRes.timeStamp).format("YYYY-MM-DD HH:mm:ss");
                            updateOrderByOid({ oid: my.oid, order_status: 'orderComment', payment: payment, pay_time:payTime }).then(()=>{
                                my.$router.push({ path: '/pay-result', query:{ partnerId: my.orderInfo.partnerId } })
                                my.isLoading = false
                                // 订单支付后，更新用户权益
                                updatePartnerAsset({ uid: my.userInfo.uid, level: my.orderInfo.partnerId, promo_code: my.promoCode, oid: my.oid })
                            })
                            // 核销优惠券
                            if(my.orderInfo.couponDiscount > 0){
                                redeemCouponsByCoid({ code: my.$route.query.couponCode })
                            }
                        }
                    },
                    // 支付取消
                    cancel: function(res){
                        if(res.errMsg === 'chooseWXPay:cancel'){
                            Toast('支付已取消');
                            // 设置15分钟倒计时time
                            my.time = 15 * 60 * 1000
                            my.isPayCancel = true
                            my.isLoading = false
                        }
                    },
                    // 支付失败
                    fail: function(res){
                        console.log(res)
                        Toast('支付失败');
                        my.isLoading = false
                    }
                });
            })
        })
    },
  },
  activated(){

  }
}
</script>

<style scoped lang="scss">
.partner-center-box {
    height: 100vh;
    background: #fafafa;
    .partner {
      margin: 65px 15px 15px;
    }
    .partner-row {
        display: flex;
        justify-content: space-around;
        margin: 20px 15px;
        .card {
            background: #fffcf5;
            border: 2px solid #eabb6a;
            border-radius: 10px;
            margin: 10px 5px;
            width: 100%;
            padding: 10px 0;
            cursor: pointer;
        }
        .card-active {
            background: #fffcf5;
            border: 2px solid #fe2b38;
            border-radius: 10px;
            margin: 10px 5px;
            padding: 10px 0;
            width: 100%;
            cursor: pointer;
        }
        .partner_type {
            color: #03081a;
            font-size: 16px;
            font-weight: 700;
            padding: 0 0 5px 0;
        }
        .price_wrap {
            display: flex;
            width: 100%;
            justify-content: center;
            .new_user_price {
                font-size: 26px;
                font-weight: 700;
                color: #f60;
                vertical-align: baseline;
                width: 50%;
                text-align: left;
            }
        }
    }
    .row {
      display: flex;
      justify-content: center;
      .activate-now {
          width: 75%;
          background: rgb(233, 185, 102);
          padding: 10px 0;
          margin: 0 0 20px 0;
          border-radius: 20px;
          color: #402200;
          font-weight: 600;
      }
      .activate-now-grey {
          width: 75%;
          background: rgba(233, 185, 102, 0.5);
          padding: 10px 0;
          margin: 0 0 20px 0;
          border-radius: 20px;
          color: rgba(64, 34, 0, 0.5);
          font-weight: 600;
      }
    }
    .coupon-row {
        background: #fffcf5;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 10px 10px;
        margin: 0 15px 20px;
        border: .1px solid rgba(234, 187, 106, 0.2);
        border-radius: 5px;
        .coupon {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #969699;
        }
    }
    .pay-details {
        padding: 15px 20px 30px;
        .title {
            padding: 0 0 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .alipay {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            .row {
                display: flex;
                align-items: center;
                img {
                    padding-right: 10px;
                }
            }
        }
        .wechat-pay {
            display: flex;
            justify-content: space-between;
            font-size: 18px;
            .row {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                img {
                    padding-right: 10px;
                }
            }
        }
        .button-row {
            position: fixed;
            bottom: 10px;
            right: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
            padding: 10px;
            padding-bottom: calc(-10px + constant(safe-area-inset-bottom));
            padding-bottom: calc(-10px + env(safe-area-inset-bottom));
            background: #fff;
            left: 0;
            box-sizing: border-box;
            max-width: 625px;
            margin: 0 auto;
            .button-buy {
                width: 80%;
                height: 38px;
                display: flex;
                line-height: 30px;
                border-radius: 30px;
                align-items: center;
                font-size: 16px;
                color: #fff;
                background: rgb(233, 185, 102);
                justify-content: center;
                border: 1px solid rgb(233, 185, 102);
            }
        }
    }
}
</style>