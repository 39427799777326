<template>
  <table class="table">
    <thead class="thead" v-show="showHeader">
      <tr class="tr">
        <th class="th" v-for="(column, index) in columns" :key="index" :style="{ backgroundColor: column.color }">{{ column.title }}</th>
      </tr>
    </thead>
    <tbody class="tbody">
      <tr v-for="(row, rowIndex) in data" :key="rowIndex" class="tr">
        <td class="td" v-for="(column, columnIndex) in columns" :key="columnIndex" :style="{ backgroundColor: column.color }">
          <div v-if="row[column.field] === 'T' || row[column.field] === 'F'">
            <img src="../../assets/images/right.png" alt="" width="16" height="16" v-if="row[column.field] === 'T'">
            <img src="../../assets/images/false.png" alt="" width="16" height="16" v-else>
          </div>
          <div v-else>
            {{ row[column.field]  }}
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MyTable',
  props: {
    columns: {
      type: Array,
      default: () => [
        { title: '', field: 'column1', color: '#ecf5ff' },
        { title: '', field: 'column2', color: '#fdf6ec' },
        { title: '', field: 'column3', color: '#f0f9eb' }
      ]
    },
    data: {
      type: Array,
      default: () => []
    },
    showHeader:{
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
    width: 100%;
    .thead {
        width: 100%;
        .tr{
            .th {
                padding: 10px;
            }
        }
    }
    .tbody {
        .tr {
            .td {
                padding: 10px;
                font-size: 14px;
            }
        }
    }
}
</style>