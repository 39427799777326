<template>
    <div :class="greyBG ? 'grey-title-nav-box' : 'title-nav-box'">
        <div class="header">
            <img src="../../assets/images/back.png" alt="" width="30" height="30" @click="goBack">
            <span>{{ title }}</span>
            <span style="color: rgba(255,255,255,0);">more</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TitleNav',
    components: {
    },
    data(){
        return {
        }
    },
    methods:{
        goBack(){
            if(this.$route.path === '/edit-personal'){
                this.$router.push('/personal')
            } else if(this.$route.path === '/coupon-more'){
                this.$router.push('/coupon')
            } else if(this.$route.path === '/coupon'){
                // 跳转页面
                if(sessionStorage.getItem('fromPath') && sessionStorage.getItem('fromPath').includes('activesvip')){
                    // 跳回开会员页面
                    if(this.$route.query.promo_code){
                        this.$router.push({ path:'/activesvip', query:{ promo_code: this.$route.query.promo_code } })
                    } else {
                        this.$router.push({ path:'/activesvip' })
                    }
                } else if(sessionStorage.getItem('fromPath') && sessionStorage.getItem('fromPath').includes('partner')){
                    // 跳回开合伙人页面
                    if(this.$route.query.promo_code){
                        this.$router.push({ path:'/partner', query:{ promo_code: this.$route.query.promo_code } })
                    } else {
                        this.$router.push({ path:'/partner' })
                    }
                } else if(sessionStorage.getItem('fromPath') && sessionStorage.getItem('fromPath').includes('cart')){
                    this.$router.push({ path:'/cart' })
                } else if(sessionStorage.getItem('fromPath') && sessionStorage.getItem('fromPath').includes('pay')){
                    if(this.$route.query.oid){
                        if(this.$route.query.gid){
                            sessionStorage.removeItem('fromPath')
                            this.$router.push({ path:'/pay', query:{ oid: this.$route.query.oid, gid: this.$route.query.gid } })
                        } else {
                            this.$router.push({ path:'/pay', query:{ oid: this.$route.query.oid } })
                        }
                    } else {
                        this.$router.push({ path:'/pay' })
                    }
                } else {
                    this.$router.push('/personal')
                }
            } else if(this.$route.path === '/partner'){
                this.$router.push('/personal')
            } else if(this.$route.path === '/partner-admin'){
                this.$router.push('/personal')
            } else if(this.$route.path === '/claim-coupon'){
                this.$router.push('/')
            } else {
                this.$router.back()
            }
        },
    },
    props:{
        title:{
            type: String,
            default:''
        },
        greyBG:{
            type: Boolean,
            default: false
        }
    }
}
</script>

<style scoped lang="scss">
.title-nav-box {
    background: #fdfdfd;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    max-width: 625px;
    margin: 0 auto;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        // font-size: 18px;
    }
}
.grey-title-nav-box {
    background: #f3f3f3;
    height: 50px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        font-size: 18px;
    }
}
</style>
